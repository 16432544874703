@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

:root {
  --mainColor100: #e6eefe;
  --mainColor200: #b1cbfe;
  --mainColor300: #7ba3fc;
  --mainColor400: ;
  --mainColor500: #195eeb;
  --mainColor600: #0045be;
  --mainColor700: #003292;
  --mainColor800: #002267;
  --mainColor900: #031133;

  --neutralColor100: #f4f6fa;
  --neutralColor200: #c2c8d2;
  --neutralColor300: #8e98a8;
  --neutralColor400: ;
  --neutralColor500: #505b74;
  --neutralColor600: ;
  --neutralColor700: #263248;
  --neutralColor800: #172337;
  --neutralColor900: ;
}

body {
  font-family: "Nunito Sans", sans-serif !important;
}

h1 {
  font-size: 2.986rem !important;
}
h2 {
  font-size: 2.488rem !important;
}
h3 {
  font-size: 2.074rem !important;
}
h4 {
  font-size: 1.728rem !important;
}
h5 {
  font-size: 1.44rem !important;
}
h6 {
  font-size: 1.2rem !important;
}
p {
  font-size: 1rem !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stepper-wrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: auto;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 10px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 10px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.active .step-counter {
  background-color: var(--mainColor300);
}

.stepper-item.completed .step-counter {
  background-color: var(--mainColor500);
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--mainColor300);
  width: 100%;
  top: 10px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

nav {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  background-color: var(--neutralColor100);
}

.nav-item {
  padding-left: 2rem;
  font-weight: 600;
}

nav .active {
  color: #2487ce !important;
}

nav .navbar-brand {
  font-weight: 600;
  font-size: 1rem;
  color: #124265 !important;
  scale: 55%;
}

nav .logo {
  scale: 65%;
}

@media only screen and (max-width: 600px) {
  #navbar {
    border-bottom: 0px;

    .navbar-toggler {
      border: 0px;
    }
    .logo {
      width: 100%;
      scale: 70%;
    }
    nav .navbar-brand {
      font-weight: 600;
      font-size: 1rem;
      color: #124265 !important;
      scale: 100%;
    }
  }

  #leftCol {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    height: fit-content !important;
  }

  #rightCol {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    max-height: fit-content !important;
  }

  .selectBtn {
    font-size: 0.85rem !important;
  }

  h4 {
    font-size: 1.5rem !important;
  }
  h6 {
    font-size: 1.15rem !important;
  }
  p {
    font-size: 0.9rem !important;
  }
}

@media only screen and (min-width: 601px) {
  #rightCol {
    min-height: 100vh !important;
  }
}

@media only screen and (max-width: 600px) {
  #rightCol {
    min-height: 75vh !important;
  }

  #leftCol {
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #fafafa;
  }  
}

#rightCol {
  background-color: var(--mainColor100);
  padding-left: 2rem;
  padding-right: 2rem;
}


.isComplete {
  display: none !important;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--neutralColor500) !important;
  opacity: 0.4 !important; /* Firefox */
}

.lightText {
  color: rgba(0, 0, 0, 0.5);
}

.smallText {
  font-size: 0.8rem !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.nextBtn {
  width: 100%;
  border-radius: 0.9rem !important;
}

#warningAlert {
  display: none;
}

.dropzone {
  background-color: white;
  border: 3px dashed var(--mainColor200);
  padding-top: 2rem;
  padding-bottom: 1.35rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.75rem;
}

.icon {
  color: var(--mainColor500);
  font-size: 5rem;
}

.redIcon {
  color: var(--neutralColor300);
  font-size: 1.25rem;
}

.fileRow {
  margin-top: 1rem !important;
  padding-top: 1.5rem;
  padding-left: 1rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
}

.iconMainColor {
  color: var(--mainColor500);
  font-size: 1rem;
}

.cloudIcon {
  font-size: 3rem;
}

.fileSize {
  font-size: 0.85rem !important;
}

.fileName {
  font-size: 1rem !important;
  margin-bottom: 0.5rem !important;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Takes up the full height of the viewport */
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* White background with slight transparency */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.progress-bar-container {
  width: 80%;
  background-color: #e0e0e0;
  border-radius: 25px;
  overflow: hidden;
  height: 20px;
}

.progress-bar {
  width: 0;
  height: 100%;
  background-color: var(--mainColor300) !important;
  border-radius: 25px;
  animation: moveProgress 2.5s linear infinite;
}

@keyframes moveProgress {
  0% {
    width: 0%;
  }
  50% {
    width: 80%;
  }
  100% {
    width: 100%;
  }
}

.card {
  border: none; /* Removes the default border */
  margin-top: 3rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.card-body {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 1rem; /* Optional: Adjusts the border radius */
}

.card-right {
  background-color: var(--mainColor300) !important;
}

.info {
  padding-top: 1rem;
  background: white;
}

.info a {
  text-decoration: none;
  color: black;
}

/* Custom styles for the switch */
.form-check-input:checked {
  background-color: #007bff; /* Blue when selected */
  border-color: #007bff;
}

.form-check-input {
  background-color: #d6d6d6; /* Gray when not selected */
  border-color: #d6d6d6;
}

.form-check-input:focus {
  box-shadow: none; /* Remove the focus shadow */
}
